import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import { useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import "./style.css";

import Filter from "../../components/Dashboard/Filter";
import Nav from "../../components/IndustryDashboard/Nav";
import IndustryInfo from "../../components/IndustryDashboard/IndustryInfo";
import LoggerDataBox from "../../components/IndustryDashboard/LoggerDataBox";
import Spinner from "../../components/Spinner";

const includesAny = (checkIn, checkFor) => {
  let result = false;
  for (let i = 0; i < checkFor.length; i++) {
    if (checkIn.includes(checkFor[i]) && !result) {
      result = true;
    }
  }
  return result;
};
const Model = ({ txt, data, show, setShow, notExpMsg = false }) => {
  return (
    <div
      className={show ? "modal fade show" : "d-none"}
      id="staticBackdropLive"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLiveLabel"
      aria-modal="true"
      role="dialog"
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        background: "rgba(255,255,255,0.5)",
        backdropFilter: "blur(5px)",
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body d-flex align-items-center justify-content-center flex-column">
            <p className="fw-bold text-danger fs-5">{txt}</p>
            {!notExpMsg && (
              <p className="fw-bold fs-6">
                Expired Parameters :{" "}
                {data.reduce(function (total, obj) {
                  return total + " " + obj.parameterStr;
                }, "")}
              </p>
            )}
            {notExpMsg && (
              <p className="fw-bold fs-6">LED Board Payment Pending</p>
            )}
            <button
              className="btn btn-secondary"
              onClick={() => setShow(false)}
            >
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndustryDashboard = () => {
  const { industry_name } = useParams();
  const [data, setData] = useState([]);

  const [errorindustryData, setErrorindustryData] = useState("");
  const [selectedIndustriesId, setSelectedIndustriesId] = useState("");
  const [industriesNames, setIndustriesNames] = useState(null);
  const [allState, setAllState] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [values, setValues] = useState(null);

  const [showModel, setShowModel] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [parametersDetailsLessThan7Days, setParametersDetailsLessThan7Days] =
    useState([]);
  const [disabled, setIsDisabled] = useState(false);
  const [realTimeDataDate, setRealTimeDatadate] = useState(null);
  const {
    userType,
    username,
    userIndustryName,
    userFrom,
    userTill,
    userSubscribtion,
    messageindustryData,
    allDevices,
    setSelectedIndustriesName,
    industriesData,
    setAllDevices,
    setMessageindustryData,
    setDeviceNameForGraph,
    setIndustriesData,
  } = useGlobalContext();

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (allDevices?.length > 0) {
        if (
          includesAny(
            allDevices.map((item) => item.device_name),
            ["M00523", "M00524", "M00525", "M00532", "M00712"]
          ) &&
          !showModel
        ) {
          setShowModel(true);
        }
      }
    }, 1000 * 5);
    return () => {
      clearTimeout(timeId);
    };
  }, [showModel, allDevices]);

  const isUserSubscribtion = (subscribtionArr) => {
    const hold = { subscribtion: true, data: [] };
    for (let i = 0; i < subscribtionArr.length; i++) {
      const { from, till, parameters } = subscribtionArr[i];
      const parameterStr = parameters.join(" ");
      const fromTime = new Date(from).getTime();
      const tillTime = 1000 * 60 * 60 * 24 * Number(till);
      const currTime = new Date().getTime();
      if (currTime > fromTime + tillTime) {
        hold.subscribtion = false;
        hold.data.push({
          from,
          till,
          parameterStr,
        });
      }
    }
    return hold;
  };

  const getDataObj = (arr) => {
    let counter = {};
    arr.forEach((element) => {
      counter[element] = (counter[element] || 0) + 1;
    });
    return counter;
  };

  useEffect(() => {
    const setDataToGraphDeviceName = async () => {
      try {
        const result = await axios(
          `${window.apiURL}/industries/${industry_name}`
        );
        setIndustriesData(result.data.data.industry);
        setSelectedIndustriesName(result.data.data.industry.industry_name);
        setAllDevices(result.data.data.industry.devices);
        setDeviceNameForGraph(result.data.data.industry.devices);
        setMessageindustryData("");
      } catch (error) {
        console.log(error.message);
      }
    };
    setDataToGraphDeviceName();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (userType === "partner") {
          const result = await axios(
            `${window.apiURL}/industries?industry_partner=${username}`
          );
          let states = [];
          let categories = [];
          let industriesName = [];
          result.data.data.industries.forEach(
            ({ state, industry_category, industry_name, _id }) => {
              if (industry_name)
                industriesName.push({
                  industry_name,
                  state,
                  industry_category,
                  industry_id: _id,
                });
              if (state) states.push(state);
              if (industry_category) categories.push(industry_category);
            }
          );
          setIndustriesNames(industriesName);
          setAllState(getDataObj(states));
          setAllCategories(getDataObj(categories));
        } else if (userType === "client") {
          const result = await axios(
            `${window.apiURL}/industries?industry_name=${userIndustryName}`
          );
          let states = [];
          let categories = [];
          let industriesName = [];
          result.data.data.industries.forEach(
            ({ state, industry_category, industry_name, _id }) => {
              if (industry_name)
                industriesName.push({
                  industry_name,
                  state,
                  industry_category,
                  industry_id: _id,
                });
              if (state) states.push(state);
              if (industry_category) categories.push(industry_category);
            }
          );
          setIndustriesNames(industriesName);
          setAllState(getDataObj(states));
          setAllCategories(getDataObj(categories));
        } else {
          const result = await axios(`${window.apiURL}/industries`);
          let states = [];
          let categories = [];
          let industriesName = [];
          result.data.data.industries.forEach(
            ({ state, industry_category, industry_name, _id }) => {
              if (industry_name)
                industriesName.push({
                  industry_name,
                  state,
                  industry_category,
                  industry_id: _id,
                });
              if (state) states.push(state);
              if (industry_category) categories.push(industry_category);
            }
          );
          setIndustriesNames(industriesName);
          setAllState(getDataObj(states));
          setAllCategories(getDataObj(categories));
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (allDevices) {
        setValues("");
        const devices = allDevices
          .map((device) => device.device_name)
          .join("+");
        try {
          const result = await axios(`${window.apiURL}/device_data/${devices}`);
          setValues(result.data.values);
          // console.log(devices)
          if (result.data.values.length > 0) {
            setMessageindustryData("");
            setErrorindustryData("");
          }
        } catch (error) {
          setMessageindustryData("");
          error.response.data.message
            ? setErrorindustryData(`ERROR: ${error.response.data.message}`)
            : setErrorindustryData(`Something went wrong: ${error.message}`);
        }
      }
    })();
  }, [industriesData, allDevices, selectedIndustriesId]);
  const showAlertsForSubscriptionLessThan7Days = async (data) => {
    const arrayOfParameter = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].daysLeft < 7) {
        arrayOfParameter.push({
          parameter: data[i].parameters,
          daysLeft: data[i].daysLeft,
        });
      }
    }
    if (arrayOfParameter.length > 0) {
      setParametersDetailsLessThan7Days(arrayOfParameter);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  // Add New Feature For Renewal Data
  const getRenewalInfo = async () => {
    try {
      const result = await axios(
        `${window.apiURL}/users/getRenewalDataById/${industry_name}`
      );
      setData(result?.data?.details);
      showAlertsForSubscriptionLessThan7Days(result?.data?.details);
      // console.log(result?.data?.details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRenewalInfo();
  }, [industry_name]);
  const handleRealTimeSheetDownload = async () => {
    if(!realTimeDataDate){ return toast.error("please select date")};
    setIsDisabled(true);
    toast.loading("downloading sheet...");
    try {
      const res = await axios.get(`${window.graphApiURL}/surveykshan-get-data-api/rawdata/excelData/download/${industriesData?.devices.map((item)=>{
        return item.device_name
      }).join("+")}/${realTimeDataDate}`, { responseType: "blob" });
      console.log("inside");
      // Extract filename from Content-Disposition header
      let filename = `Real_Time_Sheet_${industriesData?.industry_name}_${realTimeDataDate}.xlsx`; // Default filename
      const contentDisposition = res.headers["content-disposition"];
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          filename = match[1]; // Use the filename from the backend
        }
      }

      // Create a blob and trigger download
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.dismiss();
      toast.success(`Downloaded: ${filename}`);

    } catch (error) {
      toast.dismiss();
      // ✅ Fix: Convert Blob to JSON to extract error message
      if (error.response && error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
              try {
                  const jsonError = JSON.parse(reader.result);
                  toast.error(jsonError.message || "Internal Server Error");
              } catch (parseError) {
                  toast.error("Error processing response.");
              }
          };
          reader.readAsText(error.response.data);
      } else if (error.response) {
          toast.error(error.response.data.message || "Internal Server Error");
      } else if (error.request) {
          toast.error("Network error! Please check your internet connection.");
      } else {
          toast.error("An unexpected error occurred.");
      }
  } finally {
      // Ensure button is re-enabled after success or failure
      setIsDisabled(false);
  }
  };
  return (
    <div className="container-fluid p-3">
      {/* {!isUserSubscribtion(userSubscribtion).subscribtion ? (
        <Model
          txt={"Renew your account"}
          data={isUserSubscribtion(userSubscribtion).data}
        />
      ) : null}


      {allDevices?.length > 0 ? (
        includesAny(
          allDevices.map((item) => item.device_name),
          ["M00523", "M00524", "M00525", "M00532", "M00712"]
        ) && showModel ? (
          <Model
            txt={"Renew your account"}
            data={isUserSubscribtion(userSubscribtion).data}
            show={showModel}
            setShow={setShowModel}
            notExpMsg={true}
          />
        ) : null
      ) : null} */}

      <div className="row g-3">
        {userType !== "client" ? (
          <div className="col-12">
            <div className="row g-3">
              <Filter
                industriesNames={industriesNames}
                allState={allState}
                allCategories={allCategories}
                errorMessage={errorMessage}
                industriesData={industriesData}
                setIndustriesData={setIndustriesData}
                setSelectedIndustriesId={setSelectedIndustriesId}
                setSelectedIndustriesName={setSelectedIndustriesName}
                setAllDevices={setAllDevices}
                setErrorindustryData={setErrorindustryData}
                setMessageindustryData={setMessageindustryData}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* Adding subscribtion notification batch for industry those has 7 days left in expiration */}
        {isVisible && (
          <div className={`ticker-container ${isVisible ? "show" : ""}`}>
            <div className="ticker-content">
              <strong>
                {`Your subscription for the following parameters: ${parametersDetailsLessThan7Days.map(
                  (data) => `${data.parameter}: ${data.daysLeft} days left `
                )} is approaching its expiration. To ensure uninterrupted access to this portal, please consider renewing your subscription at your earliest convenience. We appreciate your continued trust in our services!`}
              </strong>
            </div>
          </div>
        )}

        {/* // Adding New Feature For Showing Date Of Subscription  */}
        <div>
          <div
            style={{
              padding: "5x",
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center",
              height: "36px",
              fontSize: "18px",
            }}
          >
            Subscription Status {`(Parameter & Days Left)`} ▼
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {data &&
              data?.map((item, i) => (
                <div key={i} style={{ display: "flex" }}>
                  <p
                    style={{
                      padding: "5px",
                      border: "1px solid black",
                      fontWeight: "bolder",
                      backgroundColor: "#A9A9A9",
                      color: "white",
                      // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                      minWidth: "80px",
                      lineHeight: "32px",
                      // borderRadius: "5px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    className="shadow"
                  >
                    {item?.parameters}
                  </p>
                  <p
                    style={{
                      padding: "5px",
                      border: "1px solid black",
                      fontWeight: "bold",
                      backgroundColor:
                        item?.daysLeft > 20
                          ? "#4dcf4d"
                          : item?.daysLeft < 1
                            ? "red"
                            : "orange",
                      // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                      minWidth: "50px",
                      textAlign: "center",
                      lineHeight: "32px",
                      // borderRadius: "12px",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}
                    className={
                      item?.daysLeft < 1
                        ? "redBlinkBg shadow"
                        : item?.daysLeft < 20 && item?.daysLeft > 0
                          ? "orangeBlinkBg shadow"
                          : "shadow"
                    }
                  >
                    {item?.daysLeft}
                  </p>
                </div>
              ))}
          </div>
        </div>

        {messageindustryData ? (
          <>
            <div className="col-12">
              <div className="row g-4">
                <div className="col-12">
                  <div className="alert alert-primary" role="alert">
                    {messageindustryData}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : errorindustryData ? (
          <>
            <div className="col-12">
              <div className="row g-4">
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    {errorindustryData}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <div className="row g-4">
                <div
                  className="pt-2"
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#f0f5ff",
                  }}
                >
                  {industriesData ? (
                    <IndustryInfo industriesData={industriesData} />
                  ) : (
                    <></>
                  )}
                </div>
                {values ? (
                  values.map((item, i) => {
                    return <LoggerDataBox key={i} data={item} />;
                  })
                ) : (
                  <>
                    <div className="col-3">
                      <div className="box_bg rounded-1 py-2 shadow">
                        <div className="p-4">
                          <Spinner />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="box_bg rounded-1 py-2 shadow">
                        <div className="p-4">
                          <Spinner />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="box_bg rounded-1 py-2 shadow">
                        <div className="p-4">
                          <Spinner />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="box_bg rounded-1 py-2 shadow">
                        <div className="p-4">
                          <Spinner />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="col-12">
          <div className="p-3 bg-white shadow-sm rounded w-100">
            <div className="row align-items-center ">
              {/* First Half: Heading */}
              <div className="col-12 col-md-6 text-center text-md-start">
                <h5 className="fw-bold mb-0">Real Time Sheet Download :-</h5>
              </div>

              {/* Second Half: Date Picker & Button */}
              <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end gap-3 mt-2 mt-md-0">
                <input
                  value={realTimeDataDate}
                  onChange={(e) => setRealTimeDatadate(e.target.value)}
                  className="form-control form-control-sm w-auto"
                  type="date"
                  style={{ minWidth: "180px", padding: "5px" }}
                />
                <button disabled={disabled} onClick={handleRealTimeSheetDownload} className="btn btn-primary px-4">Download</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="p-2 p-sm-3 bg-white shadow mt-2">
            <Nav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryDashboard;
