import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import axios from "axios";
import { Routes, Route, useNavigate } from "react-router-dom";
import { LoginForm, Loading, Navbar } from "./components";
import logo from "./images/logo.png";
import {
  Dashboard,
  IndustryDashBoard,
  Stations,
  Licenses,
  Devices,
  Consumables,
  Parameters,
  IndustryCategories,
  IndustryType,
  IndustryPartners,
  Location,
  Industry,
  People,
  // Subscription,
  Calibration,
  GenratedData,
  RawDataListing,
  Audit,
  CloseIndustry,
  ImportIndustryData,
  CameraConfig,
  DataSettings,
  CPCBlog,
  HRPCBlog,
  DLPCBLog,
  PBPCBLog,
  RSPCBLog,
  MPCBLog,
  GMDALog,
  HWRALog,
  APCBLog,
  BPCBLog,
  JHPCBLog,
  OfflineAlerts,
  DelayAlerts,
  ExceededParameterAlerts,
  DeceedParameterAlerts,
  IndustrystatusReport,
  IndustrystatusReportCpcb,
  IndustrystatusReportSpcb,
  CreateNewConsumable,
  CreateNewStation,
  CreateNewLicense,
  CreateNewDevice,
  CreateNewParameter,
  CreateNewUser,
  CreateNewIndustry,
  CreateNewLocation,
  CreateNewCamera,
  CreateNewDataSetting,
  CreateNewIndustryCategories,
  CreateNewIndustryTypes,
  CreateNewIndustryPartners,
  CreateNewGeneratedData,
  UpdateIndustry,
  UpdateUser,
  UpdateParameter,
  UpdateIndustryCategories,
  UpdateIndustryTypes,
  UpdateIndustryPartners,
  UpdateDevice,
  UpdateCamera,
  UpdateDataSetting,
  UpdateGeneratedData,
  DeleteIndustry,
  DeleteUser,
  DeleteParameter,
  DeleteDevice,
  DeleteCamera,
  DeleteDataSetting,
  DeleteIndustryCategories,
  DeleteIndustryTypes,
  DeleteIndustryPartners,
  DeleteGeneratedData,
  AssignedStations,
  RequestedAssets,
  EditProfile,
  ChangePassword,
  Nopage,
} from "./pages";
// Subscription
import Graph from "./components/Dashboard/ContentContainer/Graph";
import Map from "./components/Dashboard/ContentContainer/Map";

import IndustryGraph from "./components/IndustryDashboard/ContentContainer/IndustryGraph";
import IndustryMap from "./components/IndustryDashboard/ContentContainer/IndustryMap";
import IndustryCamera from "./components/IndustryDashboard/ContentContainer/industryCamera";

import AdminRoute from "./components/ProtectedRoutes/AdminRoute";
import ServiceEngineerRoute from "./components/ProtectedRoutes/ServiceEngineerRoute";
import ClientRoute from "./components/ProtectedRoutes/clientRoute";
import { useGlobalContext } from "./context";
import PartnerRoute from "./components/ProtectedRoutes/PartnerRoute";
import SliderUi from "./pages/IndustrySlider/SliderUi";
import Subscription from "./pages/Subscription";
import CloseDevice from "./pages/Settings/CloseDevice";
import GTPCBlog from "./pages/Reports/GTPCBLog";
import ConstantValue from "./pages/Configure/ConstantValue";
import UpdateConstantValue from "./pages/Update/ConstantValue";
import DeleteConstantValue from "./pages/Delete/ConstantValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
// import ConstantValue from "./pages/Configure/ConstantValue/index";

function App() {
  // New Feature Things
  const [subscriptionStatus, setSubscriptionStatus] = useState(true);
  // const [lastDateOfSubscribtion, setLastDateOfSubscribtion] = useState("");
  const getIndustrySubscriptionStatus = async () => {
    const username = await localStorage.getItem("username");
    try {
      const result = await axios(
        `${window.apiURL}/users/subscribtionStatus/checkUserIsSubscribedOrNot?username=${username}`
      );
      setSubscriptionStatus(result?.data?.isSubscribed);
      // setLastDateOfSubscribtion(result?.data?.date);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const refUserName = useRef();
  const refPassword = useRef();
  const [loading, setLoading] = useState(true);
  const [fetchingData, setFetchingData] = useState("");

  const [error, setError] = useState("");

  const {
    user,
    userType,
    industriesToAccess,
    setUser,
    setUserType,
    setUsername,
    setUserIndustryName,
    setUserIndustryId,
    setUserSubscribtion,
    setUserDashboardURL,
  } = useGlobalContext();

  const handleSubmit = async function (e) {
    setFetchingData("Fetching data...");
    e.preventDefault();
    try {
      const data = await axios.post(`${window.apiURL}/users/login`, {
        username: refUserName.current.value,
        password: refPassword.current.value,
      });

      if (data.status === 200 && data.data.status === "success") {
        setUser(data.data);
        setUsername(data.data.username);
        setUserIndustryName(data.data.industry);
        setUserIndustryId(data.data.industry_id);
        setUserType(data.data.usertype);
        setFetchingData("");
        setLoading(false);
        setUserSubscribtion(data.data.subscribtions);

        localStorage.setItem("username", data.data.username);
        localStorage.setItem("password", data.data.passwordStr);
        localStorage.setItem("industry_id", data.data.industry_id);

        if (data.data.usertype === "client") {
          // Checking Subscription Status
          getIndustrySubscriptionStatus();
          navigate(`/${data.data.industry_id}`);
          setUserDashboardURL(`/${data.data.industry_id}`);
          window.location.reload();
        } else {
          navigate(`/`);
        }
        // else if (data.data.usertype === "serviceengineer") {
        //   navigate(`/rawDataListing`);
        // }
      } else if (data.status === 200 && data.data.status === "failed") {
        throw new Error(data.data.msg);
      }
      if (!data.data) {
        setLoading(false);
        throw new Error("Username or password not matched!");
      }
    } catch (error) {
      setFetchingData("");
      setError(error.message);
    }
  };

  useEffect(() => {
    (async function () {
      if (
        localStorage.getItem("username") &&
        localStorage.getItem("password")
      ) {
        try {
          const data = await axios.post(`${window.apiURL}/users/login`, {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          });
          if (data.status === 200 && data.data.status === "success") {
            setUser(data.data);
            setUsername(data.data.username);
            setUserIndustryName(data.data.industry);
            setUserIndustryId(data.data.industry_id);
            setUserType(data.data.usertype);
            setFetchingData("");
            setLoading(false);
            setUserSubscribtion(data.data.subscribtions);

            // Have to remove this six line --
            localStorage.removeItem("token");
            localStorage.removeItem("industryName");
            localStorage.removeItem("industryId");
            localStorage.removeItem("userType");
            localStorage.removeItem("user");
            localStorage.removeItem("userName");

            if (data.data.usertype === "client") {
              // Checking Subscription Status
              if (!localStorage.getItem("industry_id")) {
                localStorage.setItem("industry_id", data.data.industry_id);
              }
              getIndustrySubscriptionStatus();
              navigate(`/${data.data.industry_id}`);
              setUserDashboardURL(`/${data.data.industry_id}`);
            }
            // else if (data.data.usertype === "serviceengineer") {
            //   navigate(`/rawDataListing`);
            // }
          }
          if (!data.data) {
            setLoading(false);
            setUser("");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("industry_id");
          }
        } catch (error) {
          setLoading(false);
          setUser("");
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("industry_id");
        }
      } else {
        setLoading(false);
        setUser("");
      }
    })();
  }, []);

  const refmaincontainer = useRef(null);
  const toggleClass = () => {
    let element = refmaincontainer.current;
    const withConatinerFull = "main__wrapper container__full";
    const withOutConatinerFull = "main__wrapper";

    if (element.className === withConatinerFull) {
      element.className = withOutConatinerFull;
    } else if (element.className === withOutConatinerFull) {
      element.className = withConatinerFull;
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return (
      <LoginForm
        handleSubmit={handleSubmit}
        refUserName={refUserName}
        refPassword={refPassword}
        error={error}
        setError={setError}
        fetchingData={fetchingData}
      />
    );
  }

  return (
    <div className="site__warpper">
      {/* // Added New Feature To Block Dashboard For Expired Subscription Industry  */}
      {/* <div className="site__warpper" style={{ position: "relative" }}> */}
      {!subscriptionStatus ? (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            backgroundColor: "#e8f0ff",
            zIndex: 1100,
          }}
        >
          <div className="nav__wrapper">
            <div className="nav__inner__wrapper px-3">
              <img
                className="main__logo"
                style={{ width: "200px" }}
                src={logo}
                alt=""
              />
              <FontAwesomeIcon
                onClick={() => {
                  localStorage.removeItem("username");
                  localStorage.removeItem("password");
                  localStorage.removeItem("industry_id");
                  window.location.reload();
                }}
                icon={faRightFromBracket}
                className="hum__icon text-white"
                style={{ fontSize: "25px", cursor: "pointer" }}
              />
            </div>
            <div></div>
          </div>
          <div
            style={{
              height: "90vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",
            }}
            className="font-monospace"
          >
            <div
              style={{
                alignItems: "center",
                padding: "51px",
                textAlign: "center",
                boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.176)",
                backgroundColor: "white",
                borderRadius: "12px",
              }}
            >
              <h3
                className="mb-3"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {user?.industry}
              </h3>
              <h1
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginBottom: "25px",
                }}
              >
                Your Industry Subscription Expired!!
              </h1>
              {/* <h4 style={{ marginBottom: "25px" }}>
                Subscription Renewal Pending From{" "}
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {lastDateOfSubscribtion}
                </span>
              </h4> */}
              <h5
                style={{
                  padding: "5px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
              >
                To Access This Dashboard, Please Renew Your Industry.
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* // NavBar  */}
      <Navbar toggleClass={toggleClass} setUser={setUser} />

      {/* // Other Data  */}
      <div ref={refmaincontainer} className="main__wrapper">
        <Routes>
          <Route
            element={
              <PartnerRoute userType={userType}>
                <Dashboard />
              </PartnerRoute>
            }
          >
            <Route index element={<Graph />} />
            <Route path="map" element={<Map />} />
          </Route>
          <Route
            element={
              <ClientRoute
                userType={userType}
                industriesToAccess={industriesToAccess}
              >
                <IndustryDashBoard />
              </ClientRoute>
            }
          >
            <Route path="/:industry_name" element={<IndustryGraph />} />
            <Route path="/map/:industry_name" element={<IndustryMap />} />
            <Route path="/camera/:industry_name" element={<IndustryCamera />} />
          </Route>
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/stations" element={<Stations />} />
          <Route path="consumables" element={<Consumables />} />
          <Route
            path="parameters"
            element={
              <AdminRoute userType={userType}>
                <Parameters />
              </AdminRoute>
            }
          />
          <Route
            path="/industrySlider"
            element={
              <ServiceEngineerRoute userType={userType}>
                <SliderUi />
              </ServiceEngineerRoute>
            }
          />
          <Route
            path="industryCategories"
            element={
              <AdminRoute userType={userType}>
                <IndustryCategories />
              </AdminRoute>
            }
          />
          <Route
            path="industryTypes"
            element={
              <AdminRoute userType={userType}>
                <IndustryType />
              </AdminRoute>
            }
          />
          <Route
            path="industryPartners"
            element={
              <AdminRoute userType={userType}>
                <IndustryPartners />
              </AdminRoute>
            }
          />
          <Route path="subscription" element={<Subscription />} />
          <Route path="calibration" element={<Calibration />} />
          <Route
            path="genratedData"
            element={
              <AdminRoute userType={userType}>
                <GenratedData />
              </AdminRoute>
            }
          />
          <Route
            path="rawDataListing"
            element={
              <ServiceEngineerRoute userType={userType}>
                <RawDataListing />
              </ServiceEngineerRoute>
            }
          />
          {/* create new  */}
          <Route
            path="createNewStation"
            element={
              <AdminRoute userType={userType}>
                <CreateNewStation />
              </AdminRoute>
            }
          />
          <Route
            path="createNewLicense"
            element={
              <AdminRoute userType={userType}>
                <CreateNewLicense />
              </AdminRoute>
            }
          />
          <Route
            path="createNewDevice"
            element={
              <AdminRoute userType={userType}>
                <CreateNewDevice />
              </AdminRoute>
            }
          />
          <Route
            path="createNewConsumable"
            element={
              <AdminRoute userType={userType}>
                <CreateNewConsumable />
              </AdminRoute>
            }
          />
          <Route
            path="createNewParameter"
            element={
              <AdminRoute userType={userType}>
                <CreateNewParameter />
              </AdminRoute>
            }
          />
          <Route
            path="createNewUser"
            element={
              <AdminRoute userType={userType}>
                <CreateNewUser />
              </AdminRoute>
            }
          />
          <Route
            path="createNewIndustry"
            element={
              <AdminRoute userType={userType}>
                <CreateNewIndustry />
              </AdminRoute>
            }
          />
          <Route
            path="createNewLocation"
            element={
              <AdminRoute userType={userType}>
                <CreateNewLocation />
              </AdminRoute>
            }
          />
          <Route path="createNewCamera" element={<CreateNewCamera />} />
          <Route
            path="createNewDataSetting"
            element={
              <AdminRoute userType={userType}>
                <CreateNewDataSetting />
              </AdminRoute>
            }
          />
          <Route
            path="createNewIndustryCategories"
            element={
              <AdminRoute userType={userType}>
                <CreateNewIndustryCategories />
              </AdminRoute>
            }
          />
          <Route
            path="createNewIndustryTypes"
            element={
              <AdminRoute userType={userType}>
                <CreateNewIndustryTypes />
              </AdminRoute>
            }
          />
          <Route
            path="createNewIndustryPartners"
            element={
              <AdminRoute userType={userType}>
                <CreateNewIndustryPartners />
              </AdminRoute>
            }
          />
          <Route
            path="createNewGeneratedData"
            element={
              <AdminRoute userType={userType}>
                <CreateNewGeneratedData />
              </AdminRoute>
            }
          />

          {/* Update  */}
          <Route
            path="updateIndustry/:industry_id"
            element={
              <AdminRoute userType={userType}>
                <UpdateIndustry />
              </AdminRoute>
            }
          />
          <Route
            path="updateUser"
            element={
              <AdminRoute userType={userType}>
                <UpdateUser />
              </AdminRoute>
            }
          />
          <Route
            path="updateParameter"
            element={
              <AdminRoute userType={userType}>
                <UpdateParameter />
              </AdminRoute>
            }
          />
          <Route
            path="updateIndustryCategories"
            element={
              <AdminRoute userType={userType}>
                <UpdateIndustryCategories />
              </AdminRoute>
            }
          />
          <Route
            path="updateIndustryTypes"
            element={
              <AdminRoute userType={userType}>
                <UpdateIndustryTypes />
              </AdminRoute>
            }
          />
          <Route
            path="updateIndustryPartners"
            element={
              <AdminRoute userType={userType}>
                <UpdateIndustryPartners />
              </AdminRoute>
            }
          />
          <Route
            path="updateDevice/:industry_id/:device_id"
            element={
              <AdminRoute userType={userType}>
                <UpdateDevice />
              </AdminRoute>
            }
          />
          <Route
            path="updateConstantValue/:device_name"
            element={
              <AdminRoute userType={userType}>
                <UpdateConstantValue />
              </AdminRoute>
            }
          />
          <Route
            path="updateCamera"
            element={
              <AdminRoute userType={userType}>
                <UpdateCamera />
              </AdminRoute>
            }
          />
          <Route
            path="updatedatasetting/:dataSetting_id/:parameter_id"
            element={
              <AdminRoute userType={userType}>
                <UpdateDataSetting />
              </AdminRoute>
            }
          />
          <Route
            path="updateGeneratedData"
            element={
              <AdminRoute userType={userType}>
                <UpdateGeneratedData />
              </AdminRoute>
            }
          />

          {/* delete  */}
          <Route
            path="deleteIndustry/:industry_data"
            element={
              <AdminRoute userType={userType}>
                <DeleteIndustry />
              </AdminRoute>
            }
          />
          <Route
            path="deleteUser"
            element={
              <AdminRoute userType={userType}>
                <DeleteUser />
              </AdminRoute>
            }
          />
          <Route
            path="deleteParameter"
            element={
              <AdminRoute userType={userType}>
                <DeleteParameter />
              </AdminRoute>
            }
          />
          <Route
            path="deleteDevice/:industry_id/:device_data"
            element={
              <AdminRoute userType={userType}>
                <DeleteDevice />
              </AdminRoute>
            }
          />
          <Route
            path="deleteConstantValue/:device_name"
            element={
              <AdminRoute userType={userType}>
                <DeleteConstantValue />
              </AdminRoute>
            }
          />
          <Route
            path="deleteCamera"
            element={
              <AdminRoute userType={userType}>
                <DeleteCamera />
              </AdminRoute>
            }
          />
          <Route
            path="deletedatasetting/:dataSetting_data/:parameter_id"
            element={
              <AdminRoute userType={userType}>
                <DeleteDataSetting />
              </AdminRoute>
            }
          />
          <Route
            path="deleteIndustryCategories"
            element={
              <AdminRoute userType={userType}>
                <DeleteIndustryCategories />
              </AdminRoute>
            }
          />
          <Route
            path="deleteIndustryTypes"
            element={
              <AdminRoute userType={userType}>
                <DeleteIndustryTypes />
              </AdminRoute>
            }
          />
          <Route
            path="deleteIndustryPartners"
            element={
              <AdminRoute userType={userType}>
                <DeleteIndustryPartners />
              </AdminRoute>
            }
          />
          <Route
            path="deleteGeneratedData"
            element={
              <AdminRoute userType={userType}>
                <DeleteGeneratedData />
              </AdminRoute>
            }
          />
          {/* Admin  */}
          <Route path="assignedStations" element={<AssignedStations />} />
          <Route path="requestedAssets" element={<RequestedAssets />} />
          <Route path="editProfile" element={<EditProfile />} />
          <Route path="changePassword" element={<ChangePassword />} />
          {/* configure  */}
          <Route
            path="people"
            element={
              <AdminRoute userType={userType}>
                <People />
              </AdminRoute>
            }
          />
          <Route
            path="industry"
            element={
              <AdminRoute userType={userType}>
                <Industry />
              </AdminRoute>
            }
          />
          <Route
            path="devices"
            element={
              <AdminRoute userType={userType}>
                <Devices />
              </AdminRoute>
            }
          />
          <Route
            path="constantValues"
            element={
              <AdminRoute userType={userType}>
                <ConstantValue />
              </AdminRoute>
            }
          />
          <Route
            path="location"
            element={
              <AdminRoute userType={userType}>
                <Location />
              </AdminRoute>
            }
          />
          <Route
            path="cameraconfig"
            element={
              <AdminRoute userType={userType}>
                <CameraConfig />
              </AdminRoute>
            }
          />
          <Route
            path="datasettings"
            element={
              <AdminRoute userType={userType}>
                <DataSettings />
              </AdminRoute>
            }
          />
          {/* settings  */}
          <Route path="audit" element={<Audit />} />
          <Route path="closeIndustry" element={<CloseIndustry />} />
          <Route path="closeDevice" element={<CloseDevice />} />
          <Route path="importIndustryData" element={<ImportIndustryData />} />
          {/* reports  */}
          <Route path="cpcblog" element={<CPCBlog />} />
          <Route path="gtpcblog" element={<GTPCBlog />} />
          <Route path="hrpcblog" element={<HRPCBlog />} />
          <Route path="dlpcblog" element={<DLPCBLog />} />
          <Route path="pbpcblog" element={<PBPCBLog />} />
          <Route path="rspcblog" element={<RSPCBLog />} />
          <Route path="mpcblog" element={<MPCBLog />} />
          <Route path="gmdalog" element={<GMDALog />} />
          <Route path="hwralog" element={<HWRALog />} />
          <Route path="apcblog" element={<APCBLog />} />
          <Route path="bpcblog" element={<BPCBLog />} />
          <Route path="jhpcblog" element={<JHPCBLog />} />
          <Route path="offlineAlerts" element={<OfflineAlerts />} />
          <Route path="delayAlerts" element={<DelayAlerts />} />
          <Route
            path="exceededParameterAlerts"
            element={<ExceededParameterAlerts />}
          />
          <Route
            path="deceedParameterAlerts"
            element={<DeceedParameterAlerts />}
          />
          <Route
            path="industrystatus-report"
            element={<IndustrystatusReport />}
          />
          <Route
            path="industrystatus-report-cpcb"
            element={<IndustrystatusReportCpcb />}
          />
          <Route
            path="industrystatus-report-spcb"
            element={<IndustrystatusReportSpcb />}
          />

          <Route path="*" element={<Nopage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
